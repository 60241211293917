import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Drawer,
  Chip,
  Badge,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const stripePromise = loadStripe("pk_test_51QlNssR4P0gOffgjJtxMsFeAgyOKu7xvOkll1QfExZocKskRwpi7UaPfxmwScHFNpHzs8N3E9yFyFGvmbueeNbiO00HF2g7XB7");

const timeslots = [
  { id: 1, label: "8:00 PM - 9:00 PM", price: 500 },
  { id: 2, label: "9:00 PM - 10:00 PM", price: 300 },
  { id: 3, label: "10:00 PM - 11:00 PM", price: 250 },
  { id: 4, label: "11:00 PM - 12:00 AM", price: 200 },
];

const TimeslotPicker = ({ date, selectedSlots, setSelectedSlots }) => {
  const toggleSlot = (slot) => {
    const item = { ...slot, date: date.toDateString() };
    setSelectedSlots((prev) => {
      const exists = prev.some((s) => s.id === slot.id && s.date === item.date);
      return exists
        ? prev.filter((s) => !(s.id === slot.id && s.date === item.date))
        : [...prev, item];
    });
  };

  return (
    <Box display="flex" flexWrap="wrap" gap="0.5rem" mt="2rem" justifyContent="center">
      {timeslots.map((slot) => {
        const isSelected = selectedSlots.some(
          (s) => s.id === slot.id && s.date === date.toDateString()
        );
        return (
          <Button
            key={slot.id}
            variant="contained"
            color={isSelected ? "secondary" : "primary"}
            size="small"
            sx={{ borderRadius: 2, minWidth: "100px", minHeight: "60px" }}
            onClick={() => toggleSlot(slot)}
          >
            (${slot.price / 100}) {slot.label}
          </Button>
        );
      })}
    </Box>
  );
};

const AdsPage = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const [date, setDate] = useState(new Date());
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [cartOpen, setCartOpen] = useState(false);

  useEffect(() => {
    const saved = sessionStorage.getItem("cart");
    if (saved) setSelectedSlots(JSON.parse(saved));
  }, []);

  useEffect(() => {
    sessionStorage.setItem("cart", JSON.stringify(selectedSlots));
  }, [selectedSlots]);

  const removeItem = (item) => {
    setSelectedSlots((prev) =>
      prev.filter((slot) => slot.id !== item.id || slot.date !== item.date)
    );
  };

  const handleCheckout = async () => {
    const stripe = await stripePromise;
    const res = await fetch("https://api.doorguyapp.com/ads/create-checkout-session", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ slots: selectedSlots }),
    });
    const { sessionId } = await res.json();
    await stripe.redirectToCheckout({ sessionId });
  };

  const total = selectedSlots.reduce((acc, slot) => acc + slot.price, 0);

  const tileClassName = ({ date: calendarDate, view }) => {
    if (
      view === "month" &&
      selectedSlots.some((s) => s.date === calendarDate.toDateString())
    ) {
      return "selected-calendar-tile";
    }
    return null;
  };

  return (
    <Box color={theme.palette.neutral?.main}>
      <Box textAlign="center" p="1rem" bgcolor="error.main">
        <Typography variant="h6" color="#fff">
          This is a demo site. No purchases will be charged and no ads will be displayed.
        </Typography>
      </Box>

      <Box
        width="100%"
        backgroundColor={theme.palette.background?.alt}
        p="1rem 6%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontWeight="bold" fontSize="32px" color="primary">
          DoorGuy
        </Typography>
        <IconButton onClick={() => setCartOpen(true)}>
          <Badge badgeContent={selectedSlots.length} color="primary">
            <ShoppingCartIcon sx={{ color: "#fff" }} />
          </Badge>
        </IconButton>
      </Box>

      <Box
        width={isNonMobile ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background?.alt}
      >
        <Typography fontWeight="500" variant="h3" textAlign="center" mb="1.5rem">
          Purchase Ad Slots
        </Typography>

        <Box
          sx={{
            "& .react-calendar": {
              border: "none",
              borderRadius: "8px",
              padding: "1rem",
              backgroundColor: theme.palette.grey[800],
              color: "#fff",
            },
            "& .react-calendar__navigation button": {
              color: "#fff",
              backgroundColor: "transparent",
              minWidth: "40px",
            },
            "& .react-calendar__month-view__weekdays": {
              color: theme.palette.grey[300],
            },
            "& .react-calendar__tile": {
              backgroundColor: "transparent",
              color: "#fff",
              transition: "background-color 0.3s ease",
            },
            "& .react-calendar__tile--hover": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .react-calendar__tile--now": {
              backgroundColor: theme.palette.primary.dark,
            },
            "& .react-calendar__tile--active": {
              backgroundColor: theme.palette.primary.main,
            },
            // Custom class for dates in selectedSlots
            "& .selected-calendar-tile": {
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
            },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Calendar
            onChange={setDate}
            value={date}
            tileDisabled={({ date: cDate }) =>
              cDate < new Date().setHours(0, 0, 0, 0)
            }
            tileClassName={tileClassName}
          />
        </Box>

        <TimeslotPicker
          date={date}
          selectedSlots={selectedSlots}
          setSelectedSlots={setSelectedSlots}
        />
      </Box>

      <Drawer anchor="right" open={cartOpen} onClose={() => setCartOpen(false)}>
        <Box
          width="300px"
          p="1rem"
          display="flex"
          flexDirection="column"
          height="100%"
          bgcolor={theme.palette.background?.alt}
          sx={{ color: "#fff" }}
        >
          <Typography variant="h6" mb="1rem">
            Cart
          </Typography>
          {selectedSlots.map((item, i) => (
            <Chip
              key={i}
              label={`${item.date} - ${item.label} ($${item.price / 100})`}
              onDelete={() => removeItem(item)}
              sx={{
                mb: "0.5rem",
                backgroundColor: "purple",
                color: "#fff",
              }}
            />
          ))}
          {selectedSlots.length > 0 && (
            <Box mt="auto">
              <Typography variant="body1" mb="1rem">
                Total: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total / 100)}
              </Typography>
              <Button variant="contained" onClick={handleCheckout}>
                Checkout
              </Button>
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
}

export default function Root() {
  return (
    <Elements stripe={stripePromise}>
      <AdsPage />
    </Elements>
  );
}