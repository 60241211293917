import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useMemo } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import LandingPage from "./pages/landingPage/landingPage";
import AdsPage from "./pages/ads/adsPage/adsPage";
import { SuccessPage } from "pages/ads/successPage/sucessPage";
import { CancelPage } from "pages/ads/cancelPage/cancelPage";

function App() {
    const theme = useMemo(() => createTheme(themeSettings("dark")));

    return (
        <div className="app">
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Navigate to="/landing" />} />
                        <Route path="*" element={<Navigate to="/landing" />} />
                        <Route path="/landing" element={<LandingPage />} />
                        <Route path="/ads" element={<AdsPage />} />
                        <Route path="/ads/success" element={<SuccessPage/>} />
                        <Route path="/ads/cancel" element={<CancelPage/>} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
