import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

export const SuccessPage = () => {
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

    return (
        <Box color={theme.palette.neutral?.main}>
            <Box textAlign="center" p="1rem" bgcolor="error.main">
                <Typography variant="h6" color="#fff">
                    This is a demo site. No purchases will be charged and no ads will be displayed.
                </Typography>
            </Box>
            <Box
                width="100%"
                backgroundColor={theme.palette.background?.alt}
                p="1rem 6%"
                textAlign="center"
            >
                <Typography fontWeight="bold" fontSize="32px" color="primary">
                    DoorGuy
                </Typography>
            </Box>
            <Box
                width={isNonMobileScreens ? "50%" : "93%"}
                p="2rem"
                m="2rem auto"
                borderRadius="1.5rem"
                backgroundColor={theme.palette.background?.alt}
                textAlign="center"
            >
                <Typography
                    fontWeight="500"
                    variant="h3"
                    sx={{ mb: "1.5rem" }}
                >
                    Payment Successful!
                </Typography>
                <Typography variant="body1">
                    Thank you for your purchase.
                </Typography>
            </Box>
        </Box>
    );
};