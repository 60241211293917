import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

export const CancelPage = () => {
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  
    return (
      <Box color={theme.palette.neutral?.main}>
        <Box
          width="100%"
          backgroundColor={theme.palette.background?.alt}
          p="1rem 6%"
          textAlign="center"
        >
          <Typography fontWeight="bold" fontSize="32px" color="primary">
            DoorGuy Ads
          </Typography>
        </Box>
        <Box
          width={isNonMobileScreens ? "50%" : "93%"}
          p="2rem"
          m="2rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background?.alt}
          textAlign="center"
        >
          <Typography
            fontWeight="500"
            variant="h3"
            sx={{ mb: "1.5rem" }}
          >
            Payment Canceled
          </Typography>
          <Typography variant="body1">
            Your payment was canceled. Feel free to pick another slot anytime.
          </Typography>
        </Box>
      </Box>
    );
  };