import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

class Doc extends React.Component {
    componentDidMount() {
        document.title = "DoorGuy"
    }

    render() {
        return (
            <App className="scrollhost" />
        )
    }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Doc />
);
